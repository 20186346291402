<script>
export default {
  props: {
    user: Object
  }
};
</script>
<template>
  <b-container class="page dashboard-active-status">
    
    <div class="content-wrap">
      <div v-for="a in user.announcements" :key="a.id">
        <div class="alert alert-warning" role="alert"  v-if="a.links_to !== null">
          <a :href="a.links_to" class="alert-link" target="_blank">
            {{a.announcement_text}}
          </a>
        </div>

        <div class="alert alert-primary" role="alert"  v-if="a.links_to === null">
            {{a.announcement_text}}     
        </div>

      </div>
     
     
      <h1 class="text-center">{{$t("dashboard.welcome")}} {{user.first_name+' '+user.last_name}}</h1>
      <p class="text-center" v-if="user.status=='DA'">{{$t("dashboard.status_label")}} <span class='approved'>{{$t("dashboard.status.approved")}}</span></p>

      <b-alert show variant="danger" v-if="user.status=='DE'">

        <strong>{{$t("dashboard.status.expiring")}}</strong> <span v-if="user.license.length > 0">{{user.license[0].expiry_date}}</span>

        <p>Your wysiwyg Educational license will expire soon. If you are still a student and wish to apply for a new license, 
          please submit proof of status via the “Update my Profile” option, we will then review your application. 
          If this is your last year using our Educational license, contact our educational team for information about our Student2Pro licenses.</p>
      </b-alert>

      <b-card
        bg-variant="light"
        text-variant="dark"
        title="First things first!"
        class="card-b-gap"
      >
        <b-card-text>
          Here's a list of instructions to help you download, install and activate your wysiwyg license.
        </b-card-text>
        <a href='https://cast-soft.com/educational-program/wyg-edu-activation-instructions' target='_blank'><b-button variant="primary">Instructions</b-button></a>
      </b-card>


      <b-card
        bg-variant="light"
        text-variant="dark"
        title="Download your Software"
        class="card-b-gap"
      >
        <b-card-text>
          Here you will find your Wysiwyg installer and other important tools available for download.
        </b-card-text>
        <router-link to='/download'><b-button variant="primary">Download</b-button></router-link>
      </b-card>

      

      <b-card
        bg-variant="light"
        text-variant="dark"
        title="Training and support material"
        class="card-b-gap"
      >
        <b-card-text>
          Here you will find important wysiwyg documentation and training material.
        </b-card-text>
        <router-link to='/training'><b-button variant="primary">Training</b-button></router-link>
      </b-card>
      
    </div>
  </b-container>
</template>
<style scoped>
.approved{
  color: #339933;
}
</style>
